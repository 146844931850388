import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    { path: '/coinflip', name: 'coinflip', component: () => import('../views/_CoinflipView.vue') },

    
    { path: '/', name: 'HomepageView', component: () => import('../views/Homepage.vue') },
    {
        path: '/404', name: 'NotFoundView', component: () => import('../views/other/NotFoundView.vue')
    },
    {
        path: '/:catchAll(.*)', redirect:'404'
    },

    { path: '/deposit/disabled', name: 'DepositLockView', component: () => import('../views/deposits/DepositLockView.vue') },

    { path: '/deposit/bitcoin', name: 'DepositBitcoinView', component: () => import('../views/deposits/DepositBitcoinView.vue') },
    { path: '/deposit/ethereum', name: 'DepositEthereumView', component: () => import('../views/deposits/DepositEthereumView.vue') },
    { path: '/deposit/litecoin', name: 'DepositLtcView', component: () => import('../views/deposits/DepositLtcView.vue') },
    { path: '/deposit/dogecoin', name: 'Deposit_DogeView', component: () => import('../views/deposits/Deposit_DogeView.vue') },
    { path: '/deposit/ripple', name: 'Deposit_RippleView', component: () => import('../views/deposits/Deposit_RippleView.vue') }, 
    { path: '/deposit/usdcoin', name: 'Deposit_USDCView', component: () => import('../views/deposits/Deposit_USDCView.vue') },

    { path: '/deposit/cs2', name: 'Deposit_Cs2', component: () => import('../views/deposits/Deposit_Cs2.vue') },
    { path: '/deposit/cards', name: 'Deposit_USDCView', component: () => import('../views/deposits/Deposit_CreditCard.vue') },


    { path: '/profile/set_image', name: 'ResizeView', component: () => import('../views/resize.vue') },
    { path: '/support', name: 'SupportView', component: () => import('../views/support/SupportView.vue') },
    { path: '/ticket/:id', name: 'SupportTicketView', component: () => import('../views/support/SupportTicketView.vue') },

    { path: '/admin/search', name:  '_AdminUserSearch', component: () => import('../views/other/_AdminUserSearch.vue') },
    { path: '/provably-fair', name: 'ProvablyFairView', component: () => import('../views/other/ProvablyFairView.vue') },
    

    { path: '/create-account', name: 'CreateAccountView', component: () => import('../views/CreateAccountView.vue') },

    { path: '/giveaway', name: 'GiveawayView', component: () => import('../views/GiveawayView.vue') },

    
    { path: '/withdraw', name: 'WithdrawView', component: () => import('../views/WithdrawView.vue') },
    { path: '/withdraw_m', name: 'WithdrawlMView', component: () => import('../views/WithdrawlMView.vue') },
    { path: '/withdraw/cs2', name: 'WithdrawCs2', component: () => import('../views/WithdrawCs2.vue') },


    { path: '/settings', name: 'SettingsView', component: () => import('../views/other/SettingsView.vue') },

    { path: '/admin', name: 'AdminView', component: () => import('../views/other/_AdminView.vue') },
    { path: '/admin/tax', name: 'AdminPayoutView', component: () => import('../views/other/_AdminPayout.vue') },
    { path: '/admin/logs', name: 'AdminLogView', component: () => import('../views/other/_AdminLogs.vue') },
    { path: '/admin/graph', name: 'AdminGraph', component: () => import('../views/other/_AdminGraph.vue') },


    { path: '/deposit_m', name: 'DepositMView', component: () => import('../views/deposits/DepositMView.vue') },




    { path: '/history/private', name: 'HistoryView', component: () => import('../views/history/YourHistoryView.vue') },
    { path: '/history/public', name: 'PublicHistoryView', component: () => import('../views/history/PublicHistoryView.vue') },
    
    { path: '/profile', name: 'ProfileView', component: () => import('../views/ProfileView.vue') },
    { path: '/roulette', name: 'RouletteView2', component: () => import('../views/games/RouletteView.vue') },
    { path: '/dice', name: 'DiceView', component: () => import('../views/games/DiceView.vue') },
    { path: '/jackpot', name: 'JackpotView', component: () => import('../views/games/JackpotView.vue') },
    { path: '/slot', name: 'SlotView', component: () => import('../views/games/SlotView.vue') },
    { path: '/plinko', name: 'PlinkoView', component: () => import('../views/games/PlinkoView.vue') },
    { path: '/fast', name: 'FastView', component: () => import('../views/games/FastView.vue') },

    { path: '/blackjack', name: 'BlackjackView', component: () => import('../views/games/BlackjackView.vue') },

    { path: '/upgrade', name: 'UpgraderView', component: () => import('../views/games/UpgraderView.vue') },
    { path: '/squares', name: 'ChickenView', component: () => import('../views/games/ChickenView.vue') },
    { path: '/aml-policy', name: 'AmlPolicyView', component: () => import('../views/policy/AmlPolicyView.vue') },
    { path: '/cookies-policy', name: 'CookiesPolicyView', component: () => import('../views/policy/CookiesPolicyView.vue') },
    { path: '/faq', name: 'FaqView', component: () => import('../views/policy/FaqView.vue') },
    { path: '/about', name: 'about', component: () => import('../views/policy/AboutView.vue') },
    { path: '/terms-of-service', name: 'TermsView', component: () => import('../views/policy/TermsView.vue') },
    { path: '/privacy-policy', name: 'PrivacyPolicyView', component: () => import('../views/policy/PrivacyPolicyView.vue') },


    { path: '/our-story', name: 'OurStoryView', component: () => import('../views/other/OurStoryView.vue') },




    { path: '/a-ticket/:id', name: 'AdminTicketView', component: () => import('../views/other/_AdminTicketView.vue') },

    { path: '/forgot-password', name: 'ForgotPasswordView', component: () => import('../views/other/ForgotPasswordView.vue') },


    { path: '/a/c/:code', name: 'ReferralLinkRedirect', component: () => import('../views/affiliates/ReferralLinkRedirect.vue') },
    { path: '/affiliates', name: 'AffiliatesView', component: () => import('../views/affiliates/AffiliatesView.vue') },
    { path: '/affiliates/redeem', name: 'AffiliatesViewFreeCoins', component: () => import('../views/affiliates/AffiliatesViewFreeCoins.vue') },
    { path: '/affiliates/manage', name: 'AffiliatesView_Manage', component: () => import('../views/affiliates/AffiliatesView_Manage.vue') },



    { path: '/rakeback', name: 'RakebackView', component: () => import('../views/other/RakebackView.vue') },
    { path: '/confirm_email/:token', name: 'ConfirmEmailView', component: () => import('../views/other/ConfirmEmailView.vue') },
    { path: '/reset/:token', name: 'ResetPasswordView', component: () => import('../views/other/ResetPasswordLinkView.vue') },
    { path: '/user/:username', name: 'UserView', component: () => import('../views/other/UserView.vue') },
]

const router = createRouter({

  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
}
})



export default router
